import { render, staticRenderFns } from "./changeStatus.vue?vue&type=template&id=2aa9bb90&scoped=true"
import script from "./changeStatus.vue?vue&type=script&lang=js"
export * from "./changeStatus.vue?vue&type=script&lang=js"
import style0 from "./changeStatus.vue?vue&type=style&index=0&id=2aa9bb90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa9bb90",
  null
  
)

export default component.exports